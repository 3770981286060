import React from 'react';
import { Popover } from 'antd';
import { map } from '../mapContext';
import '../style/map-reset.scss';

const IconFont = Loader.loadBaseComponent('IconFont');

@map
class MapResetTools extends React.Component {
  componentWillMount() {
    this.props.init && this.props.init(this);
  }
  mapReset = () => {
    if (this.props.mapReset) {
      this.props.mapReset();
    } else {
      const { mapMethods } = this.props;
      mapMethods.mapReset();
    }
  };
  mapZoom = zoom => {
    if (this.props.mapZoom) {
      this.props.mapZoom(zoom);
    } else {
      const { mapMethods } = this.props;
      mapMethods.setZoom(mapMethods.getZoom() + zoom);
    }
  };
  render() {
    return <MapResetLayout {...this.props} mapReset={this.mapReset} mapZoom={this.mapZoom} />;
  }
}

function MapResetLayout({ mapReset, mapZoom ,className = ''}) {
  return (
    <div className={`map-reset-tool ${className}`}>
      <Popover placement="left" content="复位" overlayClassName="map-rest-tools-popup">
        <div className="map-reset map-tool-icon" onClick={mapReset}>
          <IconFont type="icon-S_Bar_Reduction" />
        </div>
      </Popover>

      <div className="map-zoom">
        <Popover placement="left" content="放大" overlayClassName="map-rest-tools-popup">
          <div className="map-tool-icon" onClick={() => mapZoom(1)}>
            <IconFont type="icon-M_Bar_Plus" />
          </div>
        </Popover>
        <Popover placement="left" content="缩小" onClick={() => mapZoom(-1)} overlayClassName="map-rest-tools-popup">
          <div className="map-tool-icon">
            <IconFont type="icon-M_Bar_Minus" />
          </div>
        </Popover>
      </div>
    </div>
  );
}

export default MapResetTools;
