import { createMarker, createHoverMarker } from "../factory/MapFactory";

export default class ClusterTools {
  constructor() {
    this.markers = {};
  }
  clearMarkers() {
    this.markers = {};
  }
  setPointsAndCreateMarks(points, options = {}) {
    const createMarkerFn = options.hoverContent ? createHoverMarker : createMarker;
    points.forEach(item => {
      if (item.longitude && item.latitude) {
        const marker = createMarkerFn(item, options);
        this.markers[item.id || item.cid] = marker;
      }
    });
  }

  getAllMarkers() {
    return Object.values(this.markers);
  }
  getAllPoints() {
    const markerList = this.getAllMarkers();
    return markerList.map(item => {
      let data = item.getExtData();
      return data._deviceInfo;
    });
  }
  getClusterMarkerForId(id) {
    return this.markers[id];
  }

  /**
   * 根据id删除marker
   */
  removerMakers(ids) {
    this.markers = this.markers.filter(item => {
      let data = item.getExtData();
      return ids.indexOf(data.id) === -1;
    });
  }
  /**
   * 新增markers
   */

  updatePoints(changeOptions, options = {}, clusterLayer) {
    for (let i = 0; i < this.markers.length; i++) {
      const marker = this.markers[i];
    }
  }

  /**
   *
   */
  addOrUpdatePoints(points = [], options = {}, clusterLayer) {
    const createMarkerFn = options.hoverContent ? createHoverMarker : createMarker;
    points.forEach(item => {
      const marker = createMarkerFn(item, options);
      this.markers[item.id] = marker;
    });
    clusterLayer.clearMarkers();
    clusterLayer.addMarkers(this.getAllMarkers());
  }

  deletePoints(points, clusterLayer) {
    points.forEach(item => {
      delete this.markers[item.id];
    });
    clusterLayer.clearMarkers();
    clusterLayer.addMarkers(this.getAllMarkers());
  }

  getClusterMarkerForIds(ids) {
    return ids.map(id => this.getClusterMarkerForId(id));
  }
}
