import React from 'react';
import { Popover } from 'antd';
import DeviceIcon from 'src/components/DeviceIcon';
import { map } from '../mapContext';
import { xorBy } from 'lodash';
import '../style/resource-layer.scss';

const IconFont = Loader.loadBaseComponent('IconFont');

const deviceType = Dict.getDict('deviceType').filter(v => v.value !== '-1');
const deviceStatus = Dict.getDict('deviceStatus').filter(v => v.value !== '-1');

const mapFeaturesLib = [
  { value: 'bg', label: '地图背景', icon: 'icon-S_Photo_Imge' },
  { value: 'point', label: 'POI点', icon: 'icon-S_Bar_Map' },
  { value: 'road', label: '道路', icon: 'icon-S_Photo_Road' },
  { value: 'building', label: '建筑物', icon: 'icon-S_Tree_Community' }
];

@map
class ResourceLayer extends React.Component {
  constructor(props) {
    super(props);
    this.suffix = Math.random();
    this.deviceType = deviceType;
    if (props.excludeTypes && props.excludeTypes.length > 0) {
      this.deviceType = xorBy(this.deviceType, props.excludeTypes.filter(v => !!v), 'value');
    }
    this.state = {
      type: this.deviceType.map(v => v.value),
      status: deviceStatus.map(v => v.value),
      features: mapFeaturesLib.map(v => v.value)
    };
  }
  changeMapMarker(changeType, code) {
    const state = this.state;
    const index = state[changeType].indexOf(code);
    if (index > -1) {
      state[changeType].splice(index, 1);
    } else {
      state[changeType].push(code);
    }

    this.setState({ [changeType]: state[changeType] }, () => {
      const types = this.state.type.join(',').split(',');
      const status = this.state.status.join(',').split(',');
      this.props.showCustomMarker(types.filter(v => v !== ''), status.filter(v => v !== ''));
    });
  }
  changeMapFeatures(code) {
    const { mapMethods } = this.props;
    const { features } = this.state;
    const index = features.indexOf(code);
    if (index > -1) {
      features.splice(index, 1);
    } else {
      features.push(code);
    }
    this.setState({ features });
    mapMethods.setFeatures(features);
  }
  getPopupContent = () => {
    const { hideFeatures } = this.props;
    const { type, status, features } = this.state;
    return (
      <div className="map-type-popup-layout">
        <div className="type-part">
          <div className="type-name">设备种类</div>
          <div className="type-content">
            {this.deviceType.map(item => (
              <div
                className={`type-item ${type.indexOf(item.value) > -1 ? 'active' : ''} `}
                key={item.value}
                onClick={() => this.changeMapMarker('type', item.value)}>
                <span className="icon">
                  <DeviceIcon type={item.value} theme="outlined" />
                </span>
                <span className="lable-text" title={item.label}>
                  {item.label}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="type-part">
          <div className="type-name">在离线状态：</div>
          <div className="type-content">
            {deviceStatus.map(item => (
              <div
                className={`type-item ${status.indexOf(item.value) > -1 ? 'active' : ''} `}
                key={item.value}
                onClick={() => this.changeMapMarker('status', item.value)}>
                <span className="icon">
                  <IconFont type={item.value === '1' ? 'icon-S_Photo_LineOn' : 'icon-S_Photo_LineOff'} theme="outlined" />
                </span>
                <span className="lable-text">{item.label}</span>
              </div>
            ))}
          </div>
        </div>
        {this.props.children}
        {hideFeatures !== true && (
          <div className="type-part">
            <div className="type-name">地图元素：</div>
            <div className="type-content">
              {mapFeaturesLib.map(item => (
                <div
                  className={`type-item ${features.indexOf(item.value) > -1 ? 'active' : ''} `}
                  key={item.value}
                  onClick={() => this.changeMapFeatures(item.value)}>
                  <span className="icon">
                    <IconFont type={item.icon} theme="outlined" />
                  </span>
                  <span className="lable-text">{item.label}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };
  render() {
    const { getPopupContainer } = this.props;
    return (
      <div className={`tools-resource-layer tools-resource-layer-${this.suffix}`}>
        <Popover
          trigger={'click'}
          overlayClassName="resource-popup-layout"
          content={this.getPopupContent()}
          getPopupContainer={() => (getPopupContainer ? getPopupContainer() : document.getElementsByClassName(`tools-resource-layer-${this.suffix}`)[0])}
          placement="bottomLeft">
          <IconFont type="icon-S_Bar_Layer" theme="outlined" />
          资源
        <IconFont type="icon-S_Arrow_SmallDown" theme="outlined" />
        </Popover>
      </div>
    );
  }
}
export default ResourceLayer;
